module.exports = [{
      plugin: require('C:/Users/msim/code/tbt-laneshift/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/msim/code/tbt-laneshift/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-70065106-12","cookieDomain":"tbt-future-transit.stn1650.com"},
    },{
      plugin: require('C:/Users/msim/code/tbt-laneshift/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
